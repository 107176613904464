import React from "react";
import Slider from "react-slick";
import styles from "./PartnersSay.module.scss";
export default function PartnersSay() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    arrow: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          arrow: false,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <div>
      <div className={styles.partnersSaySection}>
        <div className="container-md">
          <div className={styles.pageTitle}>
            <h1>
              <span>
                What our <a> partners </a> are saying about us
              </span>
            </h1>
          </div>
          <div className={styles.partnersSlider}>
            <Slider {...settings}>
              <div className={styles.partnersBox}>
                <h2>
                  I’m extremely impressed with your application of blockchain.
                  I've been waiting for blockchain to be used just like this!
                </h2>
                <div className={styles.profileGrid}>
                  <div className={styles.profileGridItems}>
                    <img
                      alt="expertphotography"
                      src="https://expertphotography.b-cdn.net/wp-content/uploads/2018/10/cool-profile-pictures-retouching-1.jpg"
                    />
                  </div>
                  <div className={styles.profileGridItems}>
                    <p>Jennifer S. l CEO</p>
                    <h2>Paypal</h2>
                  </div>
                </div>
              </div>
              <div className={styles.partnersBox}>
                <h2>
                  Love this new marketplace platform! Especially the blockchain
                  tech and having sellers keep what’s rightfully theirs!
                </h2>
                <div className={styles.profileGrid}>
                  <div className={styles.profileGridItems}>
                    <img
                      alt="marketplace"
                      src="https://expertphotography.b-cdn.net/wp-content/uploads/2018/10/cool-profile-pictures-retouching-1.jpg"
                    />
                  </div>
                  <div className={styles.profileGridItems}>
                    <p>Jennifer S. l CEO</p>
                    <h2>Paypal</h2>
                  </div>
                </div>
              </div>
              <div className={styles.partnersBox}>
                <h2>
                  I’m extremely impressed with your application of blockchain.
                  I've been waiting for blockchain to be used just like this!
                </h2>
                <div className={styles.profileGrid}>
                  <div className={styles.profileGridItems}>
                    <img
                      alt="blockchain"
                      src="https://expertphotography.b-cdn.net/wp-content/uploads/2018/10/cool-profile-pictures-retouching-1.jpg"
                    />
                  </div>
                  <div className={styles.profileGridItems}>
                    <p>Jennifer S. l CEO</p>
                    <h2>Paypal</h2>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}
