import React from "react";
import StrategicJson from "../../animationJson/strategic-json.json";
import ProactiveJson from "../../animationJson/proactive.json";
import InvaluableJson from "../../animationJson/Invaluable1.json";
import LottiePlayOnScreen from "../../animationJson/LottiePlayOnScreen";

import styles from "./bringSolutions.module.scss";


export default function BringSolutions() {

  return (
    <div>
      <div className={styles.bringSolutionsSection}>
        <div className="container-md">
          <div className={styles.pageTitle}>
            <h1>
              Plan for the future and <a>bring solutions to the table</a>
            </h1>
          </div>
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <div className={styles.bringSolutionsSectionTextstyle}>
                <h2>Strategic</h2>
                <p>
                  This technology allows sellers to keep 100% of their sales,
                  unlike the other platforms where they lose up to 60-80% on
                  reseller fees of 20-40% on retail seller fees.
                </p>
              </div>
            </div>
            <div className={styles.gridItems}>
              <LottiePlayOnScreen
                id="StrategicJson"
                animationData={StrategicJson}
                animationSpeed={0.5}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.proactiveSection}>
        <div className="container-md">
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <LottiePlayOnScreen
                animationData={ProactiveJson}
                id="ProactiveJson"
                animationSpeed={0.5}
              />
            </div>
            <div className={styles.gridItems}>
              <div className={styles.bringSolutionsSectionTextstyle}>
                <h2>Proactive</h2>
                <p>
                  This technology allows sellers to keep 100% of their sales,
                  unlike the other platforms where they lose up to 60-80% on
                  reseller fees of 20-40% on retail seller fees.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.invaluableSection}>
        <div className="container-md">
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <div className={styles.bringSolutionsSectionTextstyle}>
                <h2>Invaluable</h2>
                <p>
                  This technology allows sellers to keep 100% of their sales,
                  unlike the other platforms where they lose up to 60-80% on
                  reseller fees of 20-40% on retail seller fees.
                </p>
              </div>
            </div>
            <div className={styles.gridItems}>
              <LottiePlayOnScreen
                animationData={InvaluableJson}
                id="InvaluableJson"
                animationSpeed={0.50}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
