import React, { useState } from "react";
import styles from "./AutomatedSolutions.module.scss";
import DashboardImage from "../../../assets/Images/dasahboard.png";
import MarketingImage from "../../../assets/Images/Marketing.png";
import RevenuImage from "../../../assets/Images/revenu.png";
import AnalyticsImage from "../../../assets/Images/analytics.png";

export default function AutomatedSolutions() {
  const [tab, setTab] = useState(1);

  return (
    <div>
      <div className={styles.automatedSolutionsSection}>
        <div className="container-md">
          <div className={styles.pageTitle}>
            <h1>
              <span>
                Automated solutions for <a>every</a> department
              </span>
            </h1>
            <div className={styles.tab}>
              <button
                className={tab === 1 && styles.activeButton}
                onClick={() => setTab(1)}
              >
                Operations
              </button>
              <button
                className={tab === 2 && styles.activeButton}
                onClick={() => setTab(2)}
              >
                Marketing
              </button>
              <button
                className={tab === 3 && styles.activeButton}
                onClick={() => setTab(3)}
              >
                Analytics
              </button>
              <button
                className={tab === 4 && styles.activeButton}
                onClick={() => setTab(4)}
              >
                Revenue
              </button>
            </div>
          </div>
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              {tab === 1 && <img src={DashboardImage} alt="DashboardImage" />}
              {tab === 2 && <img src={MarketingImage} alt="MarketingImage" />}
              {tab === 3 && <img src={RevenuImage} alt="RevenuImage" />}
              {tab === 4 && <img src={AnalyticsImage} alt="AnalyticsImage" />}
            </div>
            <div className={styles.gridItems}>
              {tab === 1 && (
                <div>
                  <h3>Operations</h3>
                  <p>
                    MyMarkit’s non-fungible digital identifier certifies
                    authenticity of products through a proprietary,
                    first-of-its-kind ledger system. White label licenses and
                    store channel integrations allow brands to integrate with
                    real-world commerce.
                  </p>
                </div>
              )}
              {tab === 2 && (
                <div>
                  <h3>Marketing</h3>
                  <p>
                    MyMarkit’s non-fungible digital identifier certifies
                    authenticity of products through a proprietary,
                    first-of-its-kind ledger system. White label licenses and
                    store channel integrations allow brands to integrate with
                    real-world commerce.
                  </p>
                </div>
              )}
              {tab === 3 && (
                <div>
                  <h3>Analytics</h3>
                  <p>
                    MyMarkit’s non-fungible digital identifier certifies
                    authenticity of products through a proprietary,
                    first-of-its-kind ledger system. White label licenses and
                    store channel integrations allow brands to integrate with
                    real-world commerce.
                  </p>
                </div>
              )}
              {tab === 4 && (
                <div>
                  <h3>Revenue</h3>
                  <p>
                    MyMarkit’s non-fungible digital identifier certifies
                    authenticity of products through a proprietary,
                    first-of-its-kind ledger system. White label licenses and
                    store channel integrations allow brands to integrate with
                    real-world commerce.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
