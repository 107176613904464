import React from "react";
import styles from "./CommerceBusiness.module.scss";
import BusinessStepImage from "../../../assets/Images/business-step.webp";
import EliminateImage from "../../../assets/Images/eliminate-image.webp";
import ConsolidateImage from "../../../assets/Images/consolidate.webp";
export default function CommerceBusiness() {
  return (
    <div>
      <div className={styles.commerceBusinessSection}>
        <div className="container-md">
          <div className={styles.pageTitle}>
            <h1>
              <span>
                This is the <a>future</a> of your commerce business
              </span>
            </h1>
          </div>
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <div className={styles.iconStyle}>
                <img src={BusinessStepImage} alt="BusinessStepImage" />
              </div>
              <h2>Bring online shopping into the 21st century</h2>
              <p>
                Automate your transactions with a private, centralized
                blockchain system using first-of-its-kind ledger technology for
                fulfillment, authentication, verification, tracking, and
                transactions.
              </p>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.iconStyle}>
                <img src={EliminateImage} alt="EliminateImage" />
              </div>
              <h2>Eliminate overhead costs completely</h2>
              <p>
                With MyMarkit’s tethered exchange conversion technology, sellers
                keep 100% of their sales–unlike the 60-80% reseller fees and
                20-40% retail seller fees other platforms require.
              </p>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.iconStyle}>
                <img src={ConsolidateImage} alt="ConsolidateImage" />
              </div>
              <h2>Consolidate digital checkout & fiat payments</h2>
              <p>
                Use the only payment processor that processes every form of
                currency while remaining in compliance with institutional banks
                and global payment methods.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
