import React from "react";
import styles from "./automateBusiness.module.scss";
export default function AutomateBusiness() {
  return (
    <div>
      <div className={styles.automateBusinessBanner}>
        <div className="container-lg">
          <h1>
            Automate your business while <a>catering</a> to your customers
          </h1>
          <div className="white-button">
            <a href="/about#ready-to-get-started">
            <button>Get Started</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
