import React from "react";

import groovyWalkAnimation from "../../animationJson/verification.json";
import MarketPlaceJson from "../../animationJson/market-place.json";
import VerificationnewJson from "../../animationJson/verificationnew.json";
import TokenJson from "../../animationJson/token.json";

import styles from "./AdoptBlockchain.module.scss";
import LottiePlayOnScreen from "../../animationJson/LottiePlayOnScreen";

export default function AdoptBlockchain() {

  return (
    <div data-scroll-section>
      <div className={styles.adoptBlockchainSection}>
        <div className="container-md">
          <div className={styles.pageTitle}>
            <h1>
              <span>
                Adopt blockchain technology for <a>your business</a>
              </span>
            </h1>
          </div>
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <div className={styles.iconAlignment}>
                <LottiePlayOnScreen
                  animationSpeed={0.5}
                  animationData={MarketPlaceJson}
                  id="MarketPlaceJson"
                />
              </div>
              <h3>Blockchain Marketplace</h3>
              <p>
                MyMarkit’s novel blockchain technology will create a new class
                of physical tokens in the cryptocurrency world for the first
                time ever. Every product on MyMarkit is tracked from the moment
                it’s listed, with a lifetime, non-fungible tag.
              </p>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.iconAlignment}>
                <LottiePlayOnScreen
                  animationSpeed={0.5}
                  animationData={VerificationnewJson}
                  id="VerificationnewJson"
                />
              </div>
              <h3>ID Verification</h3>
              <p>
                MyMarkit’s novel blockchain technology will create a new class
                of physical tokens in the cryptocurrency world for the first
                time ever. Every product on MyMarkit is tracked from the moment
                it’s listed, with a lifetime, non-fungible tag.
              </p>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.iconAlignment}>
                <LottiePlayOnScreen
                  animationSpeed={0.5}
                  animationData={groovyWalkAnimation}
                  id="groovyWalkAnimation"
                />
              </div>
              <h3>Authentication</h3>
              <p>
                Our automated authentication process uses high level blockchain
                tech, transforming the way we authenticate. Our patented
                sensor-ledger system eliminates the need for barcode inventory
                systems and offers the most technical form of secure appraisal
                and verification processes.
              </p>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.iconAlignment}>
                <LottiePlayOnScreen
                  animationSpeed={0.5}
                  animationData={TokenJson}
                  id="TokenJson"
                />
              </div>
              <h3>White Label Universal Token</h3>
              <p>
                With white label licenses and store channel integrations, NFT
                and brands can easily integrate with real-world commerce,
                tokenize with real transactions, receive card issuance, and gain
                everyday user adoption.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
