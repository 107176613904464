import React, { useEffect, useState } from "react";
import AutomatedSolutions from "./AutomatedSolutions";
import BlockchainTechnology from "./BlockchainTechnology";
import BusinessKit from "./BusinessKit";
import CommerceBusiness from "./CommerceBusiness";
import ConnectionBlockchain from "./ConnectionBlockchain";
import HeroBanner from "./HeroBanner";
import PartnersSay from "./PartnersSay";
import Webthree from "./Webthree";
import Loader from "../loader/index";
import JoinCommunity from "../Layout/Footer/JoinCommunity/index";
export default function Home() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      let body = document.getElementById("body");
      body.classList.add("bodyHidden");
    }
    setTimeout(() => {
      setLoading(false);
      let body = document.getElementById("body");
      body.classList.remove("bodyHidden");
    }, 1000);
  }, []);

  return (
    <div>
      {loading && <Loader />}
      <>
        <>
          <HeroBanner />
        </>
        <>
          <BlockchainTechnology />
        </>
        <>
          <Webthree />
        </>
        <>
          <CommerceBusiness />
        </>
        <>
          <AutomatedSolutions />
        </>
        <>
          <ConnectionBlockchain />
        </>
        <>
          <BusinessKit />
        </>
        <>
          <PartnersSay />
        </>
        <>
          <JoinCommunity />
        </>
      </>
    </div>
  );
}
