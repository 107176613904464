import React from "react";
import styles from "./physicalTethering.module.scss";
import PhysicalProductImage from "../../../assets/Images/physical-product.webp";
// import PhysicalProductImage from '../../../assets/Images/Physical-image.png';
export default function PhysicalTethering() {
  return (
    <div>
      <div className={styles.physicalTetheringAlign}>
        <div className="container-md">
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <div className={styles.colImage}>
                <img src={PhysicalProductImage} alt="PhysicalProductImage" />
              </div>
            </div>
            <div className={styles.gridItems}>
              <h1>Physical tethering and sensor ledger</h1>
              <p>
                We’ve developed an automated authentication process using high
                level blockchain tech, transforming the way we do traditional
                authentication. Our patented sensor-ledger system eliminates the
                need for barcode inventory systems and offers the most technical
                form of secure appraisal and verification processes ever
                created.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
