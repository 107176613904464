import React from "react";
import styles from "./UniversalBlockchain.module.scss";
import ConnectingImage from "../../../assets/Images/connecting-image .png";
import PaymentIcon from "../../../assets/icons/payment-icon.webp";
import GraphIcon from "../../../assets/icons/graph-icon.webp";

export default function UniversalBlockchain() {
  return (
    <div>
      <div className={styles.universalBlockchainSection}>
        <div className={styles.blockChainrightAlignment}>
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <img src={ConnectingImage} alt="ConnectingImage" />
            </div>
            <div className={styles.gridItems}>
              <h1>Connecting every corner of the world with</h1>
              <span>one universal blockchain</span>
              <h2>
                Connecting every corner of the world with{" "}
                <a>one universal blockchain</a>
              </h2>
              <p className={styles.responsiveText}>
                Beginning in 2014, we set out to create a unified solution that
                formed an organically distributed system, establishing a point
                of singularity for one universal blockchain.
              </p>
              <p className={styles.responsiveTextChild}>
                Nothing is truer to the very exchange of safe store itself than
                when money is used to transact a commodity. Taking this approach
                allowed us to discover the pivotal equation to create true
                decentralized finance. MyMarkit's controlled blockchain mines
                beget ledgers at every point of transactions themselves, whether
                in the real world or in existing Web3 environments.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.technologySection}>
        <div className="container-md">
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <div className={styles.subGrid}>
                <div className={styles.subGridItems}>
                  <img src={PaymentIcon} alt="PaymentIcon" />
                </div>
                <div className={styles.subGridItems}>
                  <h2>
                    Bridging the gap between digital checkout and fiat payment
                  </h2>
                  <p>
                    MyMarkit is the only payment processor that processes funds
                    in any form of currency and remain in compliance for
                    institutional banks and global payment methods.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.subGrid}>
                <div className={styles.subGridItems}>
                  <img src={GraphIcon} alt="GraphIcon" />
                </div>
                <div className={styles.subGridItems}>
                  <h2>Tethered exchange conversion technology</h2>
                  <p>
                    Tethered exchange conversion technology allows sellers to
                    keep 100% of sale profits, unlike other platforms that keep
                    60-80% in reseller fees and 20-40% in retail seller fees.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
