import React, { useState } from "react";
import styles from "./paymentModal.module.scss";
import classNames from "classnames";

export default function PaymentModal() {
  const [modalOpen, setModalOpen] = useState(false);
  const [textShow, setTextShow] = useState(false);

  return (
    <div>
      <div className="">
        <div className="container-md">
          <div className={styles.paymentModalCenterAlignment}>
            <button onClick={() => setModalOpen(!modalOpen)}>Modal Open</button>
          </div>
        </div>
      </div>
      {modalOpen && (
        <div className={styles.modalWrapper}>
          <div className={styles.modal}>
            <div className={styles.modalHeader}>
              <div className={styles.headerActionAlignment}>
                <div onClick={() => setModalOpen(false)}></div>
                <div></div>
                <div></div>
              </div>
              <p>My Store - Payment</p>
            </div>
            <div className={styles.paymentModalBody}>
              <h2>Payment Method</h2>
              <div className={styles.paymentMethoadBoxAlignment}>
                <div className={styles.optionBox}>
                  <div className={styles.contentAlignment}>
                    <div>
                      <input type="radio" name="iDEAL" id="iDEAL" />
                      <label htmlFor="iDEAL"></label>
                    </div>
                    <div>
                      <span>iDEAL</span>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div
                  className={
                    textShow
                      ? classNames(styles.optionBox, styles.detailsShow)
                      : classNames(styles.optionBox)
                  }
                >
                  <div
                    className={styles.contentAlignment}
                    onClick={() => setTextShow(!textShow)}
                  >
                    <div>
                      <input type="radio" name="r" id="debit card" />
                      <label htmlFor="debit card"></label>
                    </div>
                    <div>
                      <span>Credit or debit card</span>
                    </div>
                    <div></div>
                  </div>
                  <div
                    className={
                      textShow
                        ? classNames(styles.childMenuShow)
                        : classNames(styles.childMenuHidden)
                    }
                  >
                    <div className={styles.childMenuAlignment}>
                      <div className={styles.paymentInput}>
                        <label>Card number</label>
                        <input type="text" placeholder="Card number" />
                      </div>
                      <div className={styles.twoInputGrid}>
                        <div className={styles.paymentInput}>
                          <label>Expiration Date</label>
                          <input type="text" placeholder="MM/YY" />
                        </div>
                        <div className={styles.paymentInput}>
                          <label>CVC / CVV</label>
                          <input type="text" placeholder="CVC / CVV" />
                        </div>
                      </div>
                      <div className={styles.paymentInput}>
                        <label>Name on card</label>
                        <input type="text" placeholder="Jane Smith" />
                      </div>
                      <div className={styles.payButton}>
                        <button>Pay</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.optionBox}>
                  <div className={styles.contentAlignment}>
                    <div>
                      <input type="radio" name="Paysafecard" id="Paysafecard" />
                      <label htmlFor="Paysafecard"></label>
                    </div>
                    <div>
                      <span>Paysafecard</span>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div className={styles.optionBox}>
                  <div className={styles.contentAlignment}>
                    <div>
                      <input type="radio" name="GooglePay" id="GooglePay" />
                      <label htmlFor="GooglePay"></label>
                    </div>
                    <div>
                      <span>GooglePay</span>
                    </div>
                  </div>
                </div>
                <div className={styles.optionBox}>
                  <div className={styles.contentAlignment}>
                    <div>
                      <input type="radio" name="UnionPay" id="UnionPay" />
                      <label htmlFor="UnionPay"></label>
                    </div>
                    <div>
                      <span>UnionPay</span>
                    </div>
                    <div></div>
                  </div>
                  <div></div>
                </div>
                <div className={styles.optionBox}>
                  <div className={styles.contentAlignment}>
                    <div>
                      <input type="radio" name="WeChat" id="WeChat" />
                      <label htmlFor="WeChat"></label>
                    </div>
                    <div>
                      <span>WeChat Pay</span>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
