import React from "react";
import BuildingJson from "../../animationJson/building.json";
import LottiePlayOnScreen from "../../animationJson/LottiePlayOnScreen";

import styles from "./firstOfKit.module.scss";

export default function FirstOfKit() {
  return (
    <div>
      <div className={styles.firstOfKitBanner}>
        <div className={styles.leftAlignFirstOfKit}>
          <div className={styles.firstOfKitGrid}>
            <div className={styles.firstOfKitGridItems}>
              <div className={styles.textStyle}>
                <h1>
                  First-of-its-kind solutions for <a>everyday access</a>
                </h1>
                <p>
                  As ledgers accrue over time for each unique item’s every
                  transaction, valuable insights and behavior for user shopping
                  is indicative within the ledger system. The ledgers accrued
                  also indicate tangible information for commodities and their
                  demand and value.
                </p>
              </div>
            </div>
            <div className={styles.firstOfKitGridItems}>
              <LottiePlayOnScreen animationData={BuildingJson} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
