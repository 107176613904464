import React from "react";

import groovyWalkAnimation from "../../animationJson/verification.json";
import UniversalPaymentJson from "../../animationJson/universal-payment.json";
import UniversalTokenIcon from "../../animationJson/universal-token.json";
import CubesJson from "../../animationJson/cubes.json";
import LottiePlayOnScreen from "../../animationJson/LottiePlayOnScreen"

import styles from "./BlockchainTechnology.module.scss";

export default function BlockchainTechnology() {


  return (
    <div data-scroll-section>
      <div className={styles.blockchainTechnologySection}>
        <div className={styles.leftWrapper}>
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <h1>
                <span>
                  Our proprietary <a>blockchain</a> technology
                </span>
              </h1>

              <p>
                Proprietary blockchain technology tethers fund escrows directly
                to value exchange, so each of your customer transactions are
                backed straight to the source.
              </p>
              <div className={styles.mobileViewImageShow}>
                <div className={styles.mobileViewStyle}>
                      <LottiePlayOnScreen 
                        animationData={CubesJson} 
                        animationSpeed={0.5}
                      />
                </div>
              </div>
              <div className={styles.firstStep}>
                <div className={styles.iconStyle}>
                    <LottiePlayOnScreen
                      animationData={groovyWalkAnimation} 
                      animationSpeed={0.5}
                    />
                </div>
                <h2>Patented ledger and sensor technology</h2>
                <p>
                  Patented ledger and sensor technology replace manual
                  logistical systems, taking care of overhead costs without
                  taking a cut of the product. Buyers get the lowest prices
                  possible, and sellers keep 100% of the profit.
                </p>
              </div>
              <div className={styles.secStep}>
                <div className={styles.iconStyle}>
                      <LottiePlayOnScreen
                        animationSpeed={0.5}
                        animationData={UniversalPaymentJson}
                      />
                </div>
                <h2>Compatible with every currency on earth</h2>
                <p>
                  MyMarkit works with every currency on earth, and merchants
                  receive immediate payment in their preferred fund type. Users
                  can purchase their items with one currency while the merchant
                  receives another.
                </p>
              </div>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.blockchainImage}>
                <LottiePlayOnScreen animationData={CubesJson} />
              </div>
              <div className={styles.thirdImage}>
                <div className={styles.iconStyle}>
                      <LottiePlayOnScreen
                        animationSpeed={0.5}
                        animationData={UniversalTokenIcon} 
                      />
                </div>
                <h2>White label universal token</h2>
                <p>
                  MyMarkit’s non-fungible digital identifier certifies
                  authenticity of every type of product through a proprietary,
                  first-of-its-kind ledger system. White label licenses and
                  store channel integrations allow brands to integrate with
                  real-world commerce.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}