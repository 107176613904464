import React from "react";
import styles from "./NotableMilestones.module.scss";
import dummyIcon from "../../../assets/icons/steper-icon.svg";

export default function NotableMilestones() {
  return (
    <div>
      <div className={styles.notableMilestonesSection}>
        <div className="container-md">
          <div className={styles.pageTitle}>
            <h1>
              <span>Notable</span> <br />
              milestones{" "}
            </h1>
          </div>
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <div className={styles.relativeDiv}>
                <div className={styles.iconDesign}>
                  <img src={dummyIcon} alt="dummyIcon" />
                </div>
              </div>
              <div className={styles.detailsShow}>
                <h1>
                  <span>2014</span>
                </h1>
                <p>
                  we set out to create a unified solution that formed an
                  organically distributed system, establishing a point of
                  singularity for one universal blockchain.
                </p>
              </div>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.relativeDiv}>
                <div className={styles.iconDesign}>
                  <img src={dummyIcon} alt="dummyIcon" />
                </div>
              </div>
              <div className={styles.detailsShow}>
                <h1>
                  <span>2016</span>
                </h1>
                <p>
                  we set out to create a unified solution that formed an
                  organically distributed system, establishing a point of
                  singularity for one universal blockchain.
                </p>
              </div>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.relativeDiv}>
                <div className={styles.iconDesign}>
                  <img src={dummyIcon} alt="dummyIcon" />
                </div>
              </div>
              <div className={styles.detailsShow}>
                <h1>
                  <span>2021</span>
                </h1>
                <p>
                  we set out to create a unified solution that formed an
                  organically distributed system, establishing a point of
                  singularity for one universal blockchain.
                </p>
              </div>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.relativeDiv}>
                <div className={styles.iconDesign}>
                  <img src={dummyIcon} alt="dummyIcon" />
                </div>
              </div>
              <div className={styles.detailsShow}>
                <h1>
                  <span>2022</span>
                </h1>
                <p>
                  we set out to create a unified solution that formed an
                  organically distributed system, establishing a point of
                  singularity for one universal blockchain.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
