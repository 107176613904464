import React from "react";
import GoalsanimationJson from "../../animationJson/goalsanimation1.json";
import LottiePlayOnScreen from "../../animationJson/LottiePlayOnScreen";

import styles from "./reachYourGoals.module.scss";

export default function ReachYourGoals() {
  return (
    <div>
      <div className={styles.reachYourGoalsBanner}>
        <div className="container-md">
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <h1>
                Gain valuable insights and <a>reach your goals</a>
              </h1>
              <p>
                As ledgers accrue over time for each unique item’s every
                transaction, valuable insights and behavior for user shopping is
                indicative within the ledger system. The ledgers accrued also
                indicate tangible information for commodities and their demand
                and value.
              </p>
            </div>
            <div className={styles.gridItems}>
              <LottiePlayOnScreen
                id="GoalsanimationJson"
                animationData={GoalsanimationJson}
                animationSpeed={0.3}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
