import React from "react";
import styles from "./News.module.scss";
import ArticleFirst from "../../../assets/Images/article-first.webp";
import ShoppingImage from "../../../assets/Images/shopping-image.webp";
import ArticlePlaceImage from "../../../assets/Images/article-place-image.webp";

export default function News() {
  return (
    <div data-scroll-section>
      <div className={styles.newsSection}>
        <div className="container-md">
          <div className={styles.pageTitle}>
            <h1>
              <span>News</span>
            </h1>
          </div>
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <div className={styles.articleGrid}>
                <div className={styles.articleGridItems}>
                  <img src={ArticleFirst} alt="ArticleFirst" />
                </div>
                <div className={styles.articleGridItems}>
                  <h2>Article Title</h2>
                  <p>
                    MyMarkit’s non-fungible digital identifier certifies
                    authenticity of products through a proprietary,
                    first-of-its-kind ledger system.
                  </p>
                  <div className={styles.readmore}>
                    <span>Read More</span>
                    <i className="fa-solid fa-right-long"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.articleGrid}>
                <div className={styles.articleGridItems}>
                  <img src={ShoppingImage} alt="ShoppingImage" />
                </div>
                <div className={styles.articleGridItems}>
                  <h2>Article Title</h2>
                  <p>
                    MyMarkit’s non-fungible digital identifier certifies
                    authenticity of products through a proprietary,
                    first-of-its-kind ledger system.
                  </p>
                  <div className={styles.readmore}>
                    <span>Read More</span>
                    <i className="fa-solid fa-right-long"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.articleGrid}>
                <div className={styles.articleGridItems}>
                  <img src={ArticlePlaceImage} alt="ArticlePlaceImage" />
                </div>
                <div className={styles.articleGridItems}>
                  <h2>Article Title</h2>
                  <p>
                    MyMarkit’s non-fungible digital identifier certifies
                    authenticity of products through a proprietary,
                    first-of-its-kind ledger system.
                  </p>
                  <div className={styles.readmore}>
                    <span>Read More</span>
                    <i className="fa-solid fa-right-long"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
