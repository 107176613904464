import React from "react";
import Lottie from "lottie-react"
import styles from "./HeroBanner.module.scss";
import HeroSectionVideo from "../../../assets/videos/new-hero-text.mp4";
import HeroHomeDesktop from "../../animationJson/home_hero_extra_wide.json";
import HeroHomeMobile from "../../../assets/Images/home-hero-mobile.json";
import HeroHomeTablet from "../../animationJson/home_hero_tablet_2.json";
import ConnectsIcon from "../../../assets/icons/hero-banner-icon.svg";
import ExistingIcon from "../../../assets/icons/existing.png";
import LockIcon from "../../../assets/icons/lock-icon.svg";
export default function HeroBanner() {
  return (
    <div>
      <div></div>
      <div className={styles.heroSection}>
        <div className={styles.desktopHeroAnimation} >
          <Lottie animationData={HeroHomeDesktop} />
        </div>
        <video
          typeof="video"
          src={HeroSectionVideo}
          loop
          muted
          preLoad="auto"
          tabindex="0"
          autoPlay
        >
          {" "}
        </video>
        <div className={styles.mobileViewGifPreload}>
          <div style={{
            position: 'relative'
          }} className={styles.mobileHeroAnimation}>
            <div style={{
                position: 'absolute',
                zIndex: 2,
                height: '30%',
                width: '100%',
                background: 'linear-gradient(rgb(255, 255, 255, 0.6) 0%, rgba(0, 0, 0, 0) 100%)'
            }} />
            <Lottie animationData={HeroHomeMobile} />
          </div>
          <div className={styles.tabletHeroAnimation}>
            <Lottie animationData={HeroHomeTablet} />
          </div>
        </div>
        <div className={styles.heroSectionText}>
          <div>
            <div className={styles.heroText}>
              <h1>
                <span>Meet the World’s First</span>
              </h1>

              <h2>Transactional Blockchain</h2>
              <p>
                A commerce-powered blockchain mining ledgers from every IRL &
                Web3 transaction.
              </p>
              <a href="/universal-blockchain">
                <div className={styles.buttonCenterAlign}>
                  <button>Learn more</button>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.responsiveBottomSection}>
        <div className={styles.responsiveViewGrid}>
          <div className={styles.firstSection}>
            <div className={styles.iconCenterAlignment}>
              <img src={ConnectsIcon} alt="ConnectsIcon" />
            </div>
            <div className={styles.bottomContentText}>
              <p>
                Transactional chain data connects all algorithm-based
                blockchains
              </p>
            </div>
          </div>
          <div className={styles.firstSection}>
            <div className={styles.iconCenterAlignment}>
              <img src={LockIcon} alt="LockIcon" />
            </div>
            <div className={styles.bottomContentText}>
              <p>Safe store of value for every commodity money can purchase</p>
            </div>
          </div>
          <div className={styles.firstSection}>
            <div className={styles.iconCenterAlignment}>
              <img src={ExistingIcon} alt="ExistingIcon" />
            </div>
            <div className={styles.bottomContentText}>
              <p>Works with every currency across all existing compliance </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
