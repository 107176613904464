import React from "react";
import styles from "./authenticationLifecycle.module.scss";
import InspectionIcon from "../../../assets/icons/inspection-icon.webp";
import PhysicalIcon from "../../../assets/icons/physical-icon.webp";
import LedgerIcon from "../../../assets/icons/ledger-icon.webp";
export default function AuthenticationLifecycle() {
  return (
    <div>
      <div className={styles.authenticationLifecycle}>
        <div className="container-md">
          <div className={styles.pageTitle}>
            <h1>
              The authentication <a>lifecycle</a>
            </h1>
            <p>
              Our patented sensor-ledger system eliminates the need for barcode
              inventory systems and offers the most technical form of secure
              appraisal and verification processes ever created.
            </p>
          </div>
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <div className={styles.steperArrow}></div>
              <div className={styles.iconAlignment}>
                <img src={InspectionIcon} alt="InspectionIcon" />
              </div>
              <h2>1. Inspection</h2>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.steperArrow}></div>
              <div className={styles.iconAlignment}>
                <img src={PhysicalIcon} alt="PhysicalIcon" />
              </div>
              <h2>2. Physical Tethering</h2>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.steperArrow}></div>
              <div className={styles.iconAlignment}>
                <img src={LedgerIcon} alt="LedgerIcon" />
              </div>
              <h2>3. Ledger Recording</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
