import React, { useEffect, useState } from "react";
import AdoptBlockchain from "./AdoptBlockchain";
import GlobalNetwork from "./GlobalNetwork";
import InformedDecisions from "./InformedDecisions";
import ProcessTypeDetails from "./ProcessTypeDetails";
import styles from "./UniversalBlockchain.module.scss";
import WhiteHeader from "../Layout/WhiteHeader";
import UniversalBlockchainVideo from "../../assets/videos/universal-blockchain-video.mp4";
import UniversalBlockchainGif from "../../assets/Images/universal-gif.gif";
import Loader from "../loader/index";
import ScrollbarHeader from "../Layout/scrollbarHeader";
import useStickyHeader from "../../hook/useStickyHeader";
export default function UniversalBlockchain() {
  const [loading, setLoading] = useState(true);
  const { scrollRef, isSticky } = useStickyHeader();

  useEffect(() => {
    if (loading) {
      let body = document.getElementById("body");
      body.classList.add("bodyHidden");
    }
    setTimeout(() => {
      setLoading(false);
      let body = document.getElementById("body");
      body.classList.remove("bodyHidden");
    }, 2000);
  }, []);

  return (
    <div ref={scrollRef}>
      {loading && <Loader />}
      <>
        <div className={styles.universalBlockchainSectionAlign}>
          <video loop muted autoPlay={true} preLoad="auto">
            <source src={UniversalBlockchainVideo} type="video/mp4" />
          </video>
          <div className={styles.mobileDeviceGifLoad}>
            <img src={UniversalBlockchainGif} alt="UniversalBlockchainGif" />
          </div>
          <div className={styles.allContentAlignment}>
            {isSticky ? (
              <>
                <ScrollbarHeader />
              </>
            ) : (
              <>
                <WhiteHeader />
              </>
            )}

            <div className="container">
              <div className={styles.bannerTextAlignment}>
                <div className={styles.textboxCenter}>
                  <div className={styles.textbox}>
                    <h1>
                      <span>
                        <a>Universal</a> Blockchain Technology
                      </span>
                    </h1>
                    <p>
                      An all-inclusive platform built from the ground up on a
                      private, centralized blockchain system that mines ledgers
                      between users based on commerce transactions in an open
                      market.
                    </p>
                    <div>
                      <a href="/about#ready-to-get-started">
                        <button>Get Started</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <>
          <ProcessTypeDetails />
        </>
        <>
          <InformedDecisions />
        </>
        <>
          <AdoptBlockchain />
        </>
        <>
          <GlobalNetwork />
        </>
      </>
    </div>
  );
}
