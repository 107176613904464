import React from "react";
// import PaymentInstantlyImage from  "../../../assets/Images/payment-instantly.gif";
import PaymentInstantly from "../../PaymentInstantly";
import UtilizeJson from "../../animationJson/Utilize-json.json";
import MineJson from "../../animationJson/Mine-json.json";
import LottiePlayOnScreen, { ShowOnScreen } from "../../animationJson/LottiePlayOnScreen";

import styles from './processType.module.scss';

export default function ProcessType() {

  return (
    <div>
        <div className={styles.processTypeAlignment}>
            <div className='container-md'>
                <div className={styles.grid}>
                    <div className={styles.gridItems}>
                        <ShowOnScreen delay={.8}>
                          <PaymentInstantly />
                        </ShowOnScreen>
                        {/* <img src={PaymentInstantlyImage} alt="PaymentInstantlyImage"/> */}
                    </div>
                    <div className={styles.gridItems}>
                        <div className={styles.blockchainProcessTypeText}>
                            <h1>Process any type of payment instantly</h1>
                            <p>
                                All forms of digital and fiat currencies can instantly 
                                transact for both purchases and sales, without gas fees.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.utilizeWorldWallet}>
            <div className='container-md'>
                <div className={styles.grid}>
                    <div className={styles.gridItems}>
                        <div className={styles.blockchainProcessTypeText}>
                            <h1>Utilize the world's first universal wallet</h1>
                            <p>
                                With a blockchain mining system backed to commodities from direct transactions, MyMarkit is the only 
                                blockchain-enabled marketplace in one of the largest payment platforms worldwide. 
                            </p>
                        </div>
                    </div>
                    <div className={styles.gridItems}>
                        <LottiePlayOnScreen id="UtilizeJson" animationData={UtilizeJson} animationSpeed={0.5}/>
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.directlyFromLive}>
            <div className='container-md'>
                <div className={styles.grid}>
                    <div className={styles.gridItems}>
                    <LottiePlayOnScreen id="MineJson" animationData={MineJson} ranimationSpeed={0.5}/>
                    </div>
                    <div className={styles.gridItems}>
                        <div className={styles.blockchainProcessTypeText}>
                            <h1>Mine ledgers directly from live transactions</h1>
                            <p>
                                A patented, internal marketplace blockchain attaches true value as an IP or escrow ledger that is 
                                directly backed by commodities, creating a lossless way to purchase. 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
