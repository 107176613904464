import React from "react";
import styles from "./GlobalNetwork.module.scss";
export default function GlobalNetwork() {
  return (
    <div>
      <div className={styles.globalNetworkBanner} data-scroll-section>
        <div className="container-md">
          <div className={styles.contentRightAlign}>
            <div></div>
            <div>
              <h1>
                <span>
                  Build a <a>global network</a>
                  <br /> to expand your business
                </span>
              </h1>
              <a href="/about#ready-to-get-started">
                <button>Get Started</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
