import React from "react";
import styles from "./exploreTheBenefits.module.scss";
import CommerceSupplyChain from "../../../assets/Images/commerce-supply-chain.webp";
import TransactionsPayments from "../../../assets/Images/transactions-payments.webp";
import FiatDigital from "../../../assets/Images/fiat-digital.webp";
export default function ExploreTheBenefits() {
  return (
    <div>
      <div className={styles.exploreTheBenefitsSection}>
        <div className="container-md">
          <div className={styles.pageTitle}>
            <h1>
              Explore the benefits of a <a>blockchain marketplace</a>
            </h1>
          </div>
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <div className={styles.imageCenteralignment}>
                <img src={CommerceSupplyChain} alt="CommerceSupplyChain" />
              </div>
              <h2>Commerce and Supply Chain</h2>
              <p>
                IOT and Ledger technology allows infallible inventory tracing,
                supply chain management, and system logistics automation.
              </p>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.imageCenteralignment}>
                <img src={TransactionsPayments} alt="TransactionsPayments" />
              </div>
              <h2>Transactions and Payments</h2>
              <p>
                IRL and Web3 universal payment processors make using blockchain
                as easy as everyday consumer adoption.
              </p>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.imageCenteralignment}>
                <img src={FiatDigital} alt="FiatDigital" />
              </div>
              <h2>Fiat and Digital Currency</h2>
              <p>
                Every form of currency is seamlessly transacted across
                MyMarkit’s fintech ecosystem using Markitchain.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
