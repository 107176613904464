import React from "react";
import BarcodeJson from "../../animationJson/barcode.json";
import LottiePlayOnScreen from "../../animationJson/LottiePlayOnScreen";

import styles from "./ConnectionBlockchain.module.scss";

export default function ConnectionBlockchain() {

  return (
    <div>
      <div className={styles.connectionBlockchainSection}>
        <div className="container">
          <div className={styles.connectionBoxCenter}>
            <div className={styles.connectionBox}>
              <div className={styles.barCodeIconAlignment}>
                    <LottiePlayOnScreen loop={false} animationData={BarcodeJson} />
              </div>
              <h1>
                <span>
                  Connecting the world in every way to{" "}
                  <a>one universal blockchain.</a>
                </span>
              </h1>
              <a href="/universal-blockchain">
                <div>
                  <button>Learn More</button>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
