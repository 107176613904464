import React, { useEffect, useState } from "react";
import WhiteHeader from "../Layout/WhiteHeader";
import AskedQuestions from "./askedQuestions";
import GrowthAndSecurity from "./growthAndSecurity";
import PaymentMethods from "./paymentMethodsSection/index";
import PaymentService from "./paymentService/index";
import styles from "./universalCurrency.module.scss";
import Loader from "../loader/index";
import ScrollbarHeader from "../Layout/scrollbarHeader";
import useStickyHeader from "../../hook/useStickyHeader";
export default function UniversalCurrency() {
  const [loading, setLoading] = useState(true);
  const { scrollRef, isSticky } = useStickyHeader();

  useEffect(() => {
    if (loading) {
      let body = document.getElementById("body");
      body.classList.add("bodyHidden");
    }
    setTimeout(() => {
      setLoading(false);
      let body = document.getElementById("body");
      body.classList.remove("bodyHidden");
    }, 2000);
  }, []);

  return (
    <div ref={scrollRef}>
      {loading && <Loader />}
      <>
        <div className={styles.universalCurrencyBanner}>
          {isSticky ? (
            <>
              <ScrollbarHeader />
            </>
          ) : (
            <>
              <WhiteHeader />
            </>
          )}

          <div className="container-md">
            <div className={styles.universalCurrencyAlignment}>
              <h1>
                <a>Universal</a> Currency
              </h1>
              <p>
                Tethered exchange conversion means instant transaction of all
                forms of currency, digital and fiat, regardless of merchant
                acceptance.
              </p>
              <div className="blue-button">
                <a href="/about#ready-to-get-started">
                  <button>Get Started</button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <>
          <PaymentService />
        </>
        <>
          <PaymentMethods />
        </>
        <>
          <AskedQuestions />
        </>
        <>
          <GrowthAndSecurity />
        </>
      </>
    </div>
  );
}
