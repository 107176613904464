import React from "react";
import styles from "./paymentMethods.module.scss";
import InvalidIcon from "../../../assets/icons/invalid-icon.png";
import DolorIcon from "../../../assets/icons/dolor.png";
import SrokeIcon from "../../../assets/icons/sroke-icon.svg";
import BitCoinsIcon from "../../../assets/icons/bit-coins.svg";
import ArrowDesign from "../../../assets/icons/arrow-world.svg";
import EuroSymbol from "../../../assets/icons/euro-symbol.svg";
export default function PaymentMethods() {
  return (
    <div>
      <div className={styles.paymentMethodsBanner}>
        <div className="container-md">
          <h1>
            Encapsulates and tethers currencies and payment methods{" "}
            <a>around the world</a>
          </h1>
          <div className={styles.boxCenterAlignment}>
            <div className={styles.box}>
              <div className={styles.firstRowAlignment}>
                <div className={styles.roundIcon}>
                  <img src={InvalidIcon} alt="InvalidIcon" />
                </div>
                <div className={styles.roundIcon}>
                  <img src={DolorIcon} alt="DolorIcon" />
                </div>
                <div className={styles.roundIcon}>
                  <img src={EuroSymbol} alt="EuroSymbol" />
                </div>
              </div>
              <div className={styles.secRowAlignment}>
                <div className={styles.roundIcon}>
                  <img src={SrokeIcon} alt="SrokeIcon" />
                </div>
                <div className={styles.roundIcon}>
                  <img src={BitCoinsIcon} alt="BitCoinsIcon" />
                </div>
                <div className={styles.roundIcon}>
                  <img src={ArrowDesign} alt="ArrowDesign" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
