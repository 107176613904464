import React, { useRef, useState } from "react";
import styles from "./WhiteHeader.module.scss";
import MenuIcon from "../../../assets/icons/menu.svg";
import MyMarkitInc from "../../../assets/logo/my-markit-inc.svg";
import CloseIcon from "../../../assets/icons/close.svg";
import MyMarkitIncWhite from "../../../assets/logo/my-markit-inc.svg";
import classNames from "classnames";
import useOutsideAlerter from "../../../hook/useOutsideAlert";

export default function WhiteHeader() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [headerMenu, setHeaderMenu] = useState(false);
  const [headerMenuApi, setHeaderMenuApi] = useState(false);

  const onClickSideMenu = () => {
    setMobileMenu(!mobileMenu);
    let body = document.getElementById("body");
    body.classList.toggle("overFlow");
  };

  const solutionsDropDownRef = useRef()
  useOutsideAlerter(solutionsDropDownRef, () => {
    setHeaderMenu(false)
  })

  const documentationDropDownRef = useRef()
  useOutsideAlerter(documentationDropDownRef, () => {
    setHeaderMenuApi(false)
  })

  const dropDownCaretClasses = classNames("fa-solid fa-angle-down", {
    'rotate-180': headerMenu
  })

  const dropDownAPICaretClasses = classNames("fa-solid fa-angle-down", {
    'rotate-180': headerMenuApi
  })

  return (
    <>
      <div className={styles.whiteHeader}>
        <div className="container-fluid">
          <div className={styles.headerAlignment}>
            <div className={styles.logo}>
              <a href="/">
                <img src={MyMarkitInc} alt="MyMarkitInc" />
              </a>
            </div>
            <div className={styles.menu}>
              <nav>
                <ul>
                  <li ref={solutionsDropDownRef}>
                    <div onClick={() => setHeaderMenu(!headerMenu)}>
                      <span>Solutions</span>
                      <i className={dropDownCaretClasses}></i>
                    </div>
                    <div
                      className={
                        headerMenu
                          ? "header-dropdown header-dropdown-without-back header-dropdown-show"
                          : "header-dropdown header-dropdown-without-back header-dropdown-hidden"
                      }
                    >
                      <div className="header-dropdown-alignment">
                        <a href="/universal-blockchain">Universal Blockchain</a>
                        <a href="/marketing-analytics">Marketing Analytics</a>
                        <a href="/universal-currency">Universal Currency</a>
                        <a href="/blockchain-marketplace">
                          Blockchain Marketplace
                        </a>
                        <a href="/universal-payment-processor">Universal Payment Processor (coming soon)</a>
                        <a hre="/universal-wallet">Universal Wallet (coming soon)</a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="/white-label">
                      <span>WHITE LABEL</span>
                    </a>
                  </li>
                  <li>
                    <a href="/authentication">
                      <span>Authentication</span>
                    </a>
                  </li>
                  <li>
                    <a href="/about">
                      <span>About</span>
                    </a>
                  </li>
                  <li ref={documentationDropDownRef}>
                    <div onClick={() => setHeaderMenuApi(!headerMenuApi)}>
                      <span>DOCUMENTATION</span>
                      <i className={dropDownAPICaretClasses}></i>
                    </div>
                    <div
                      className={
                        headerMenuApi
                          ? "header-dropdown header-dropdown-without-back header-dropdown-show"
                          : "header-dropdown header-dropdown-hidden header-dropdown-without-back"
                      }
                    >
                      <div className="header-dropdown-alignment">
                        {/* <a>Plug-in Info</a> */}
                        <a href="https://mymarkit.redoc.ly/" target="_blank" rel="noreferrer" >API Info</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
            <div
              className={styles.mobileMenu}
              onClick={() => onClickSideMenu()}
            >
              <img src={MenuIcon} alt="menu" />
            </div>
          </div>
        </div>
      </div>

      {mobileMenu && <div className={styles.mobileSidebarWrapper}></div>}
      <div
        className={
          mobileMenu
            ? classNames(styles.mobileViewSidebar, styles.sidebarShow)
            : classNames(styles.mobileViewSidebar, styles.sidebarHidden)
        }
      >
        <div className={styles.mobileHeader}>
          <div>
            <a href="/">
              <img src={MyMarkitIncWhite} alt="MyMarkitIncWhite" />
            </a>
          </div>
          <div onClick={() => onClickSideMenu()}>
            <img src={CloseIcon} alt="CloseIcon" />
          </div>
        </div>
        <div className={styles.mobileBody}>
          <a href="/" onClick={() => setMobileMenu(false)}>
            Home
          </a>
          <a href="/universal-blockchain" onClick={() => setMobileMenu(false)}>
            Universal Blockchain
          </a>
          <a href="/marketing-analytics" onClick={() => setMobileMenu(false)}>
            Marketing Analytics
          </a>
          <a href="/universal-currency" onClick={() => setMobileMenu(false)}>
            Universal Currency
          </a>
          <a
            href="/blockchain-marketplace"
            onClick={() => setMobileMenu(false)}
          >
            Blockchain Marketplace
          </a>
          <a href="/universal-payment-processor" onClick={() => setMobileMenu(false)}>
            Universal Payment Processor (coming soon)
          </a>
          <a href="/universal-wallet" onClick={() => setMobileMenu(false)}>
            Universal Wallet (coming soon)
          </a>
          <a href="/white-label" onClick={() => setMobileMenu(false)}>
            White Label
          </a>
          <a href="/authentication" onClick={() => setMobileMenu(false)}>
            Authentication
          </a>
          <a href="/about" onClick={() => setMobileMenu(false)}>
            About
          </a>
          <a href="https://mymarkit.redoc.ly/" target="_blank" rel="noreferrer" onClick={() => setMobileMenu(false)}>
            API Info
          </a>
        </div>
      </div>
    </>
  );
}
