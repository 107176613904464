import React from "react";
import Lottie from "lottie-react";
import styles from "./Webthree.module.scss";
import WebThreeJson from "../../animationJson/web3-json-small.json";
import WebThreeJsonMobile from "../../animationJson/webthree-mobile.json";
export default function Webthree() {
  return (
    <div>
      <div className={styles.WebthreeSection}>
        <div className="container-md">
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <h1>
                <span>
                  Finally, a <a>true</a> <br /> Web3 is here
                </span>
              </h1>
            </div>
            <div className={styles.gridItems}>
              <p>
                MyMarkit's proprietary chain, Markitchain, is the first
                transactional blockchain that mines ledgers directly from live
                transactions across various points of commerce. With SAAS and
                blockchain digital platforms that propagate ledgers across a
                real-world fintech ecosystem of applications, MyMarkit is able
                to provide first-of-its-kind solutions for everyday access to
                blockchain utility.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.finallyWebThreeAnimation}>
        <Lottie animationData={WebThreeJson} />
      </div>
      <div className={styles.wrbThreeJsonMobileViewLoad}>
        <Lottie animationData={WebThreeJsonMobile} />
      </div>
    </div>
  );
}
