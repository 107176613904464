import React from "react";
import styles from "./BusinessKit.module.scss";
import MarkitChainImage from "../../../assets/Images/markit-chain.png";
import MarkitChainImageMobile from "../../../assets/Images/markit-chain-mobile.png";
import MarkitShop from "../../../assets/Images/markit-shop.webp";
import MarkitShopMobile from "../../../assets/Images/markit-shop-mobile.png";
import PlaceImage from "../../../assets/Images/place-image.webp";
import PlaceImageMobile from "../../../assets/Images/place-image-mobile.png";
import MarkitCard from "../../../assets/Images/markit-card.webp";
import MarkitCardMobile from "../../../assets/Images/markit-card-mobile.webp";
import MarkitIqImage from "../../../assets/Images/markit-iq.webp";
import MarkitIqImageMobile from "../../../assets/Images/markit-iq-mobile.png";
import MarkitPayImage from "../../../assets/Images/markit-pay.webp";
import MarkitPayImageMobile from "../../../assets/Images/markit-pay-mobile.png";
import ChainIcon from "../../../assets/Images/chain-icon.webp";
import ShopIcon from "../../../assets/icons/shop.webp";
import PlaceIcon from "../../../assets/icons/place-icon.svg";
import PayIcon from "../../../assets/icons/pay-icon.webp";
import IqIcon from "../../../assets/icons/iq-icon.svg";
import CardIcon from "../../../assets/icons/card-icon.svg";

export default function BusinessKit() {
  return (
    <div>
      <div className={styles.businessKitSection}>
        <div className="container-md">
          <div className={styles.pageTitle}>
            <h1>
              <span>
                Your <a>complete</a> commerce business kit
              </span>
            </h1>
          </div>
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <div className={styles.subGrid}>
                <div className={styles.subGridItems}>
                  <div className={styles.cardImage}>
                    <img src={MarkitChainImage} alt="MarkitChainImage" />
                    <img
                      src={MarkitChainImageMobile}
                      alt="MarkitChainImageMobil"
                    />
                  </div>
                </div>
                <div className={styles.subGridItems}>
                  <div className={styles.chainIcon}>
                    <img src={ChainIcon} alt="ChainIcon" />
                    {/* <img src={ChainIconMobile} alt="ChainIconMobile" /> */}
                  </div>
                  <h2>MarkitChain</h2>
                  <p>
                    A patented access point for a universal transactional
                    blockchain that mines from all commerce transactions (IRL &
                    Web3)
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.subGrid}>
                <div className={styles.subGridItems}>
                  <div className={styles.cardImage}>
                    <img src={MarkitShop} alt="MarkitShop}" />
                    <img src={MarkitShopMobile} alt="MarkitShopMobile}" />
                  </div>
                </div>
                <div className={styles.subGridItems}>
                  <div className={styles.chainIcon}>
                    <img src={ShopIcon} alt="ShopIcon" />
                    {/* <img src={ShopIconMobile} alt="ShopIconMobile" /> */}
                  </div>
                  <h2>MarkitShop</h2>
                  <p>
                    An enterprise ecommerce powered by the transactional
                    blockchain (white label)
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.subGrid}>
                <div className={styles.subGridItems}>
                  <div className={styles.cardImage}>
                    <img src={PlaceImage} alt="PlaceImage}" />
                    <img src={PlaceImageMobile} alt="PlaceImageMobile" />
                  </div>
                </div>
                <div className={styles.subGridItems}>
                  <div className={styles.chainIcon}>
                    <img src={PlaceIcon} alt="PlaceIcon" />
                    {/* <img src={PlaceIconMobile} alt="PlaceIconMobile" /> */}
                  </div>
                  <h2>MarkitPlace</h2>
                  <p>
                    An enterprise marketplace powered by the transactional
                    blockchain (white label)
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.subGrid}>
                <div className={styles.subGridItems}>
                  <div className={styles.cardImage}>
                    <img src={MarkitPayImage} alt="MarkitPayImage}" />
                    <img
                      src={MarkitPayImageMobile}
                      alt="MarkitPayImageMobile"
                    />
                  </div>
                </div>
                <div className={styles.subGridItems}>
                  <div className={styles.chainIcon}>
                    <img src={PayIcon} alt="PayIcon" />
                    {/* <img src={PayIconMobile} alt="PayIconMobile" /> */}
                  </div>
                  <h2>MarkitPay</h2>
                  <p>
                    A universal payment processor that is easily used with any
                    form of currency with full compliance (white label)
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.subGrid}>
                <div className={styles.subGridItems}>
                  <div className={styles.cardImage}>
                    <img src={MarkitIqImage} alt="MarkitIqImage" />
                    <img src={MarkitIqImageMobile} alt="MarkitIqImageMobile" />
                  </div>
                </div>
                <div className={styles.subGridItems}>
                  <div className={styles.chainIcon}>
                    <img src={IqIcon} alt="IqIcon" />
                    {/* <img src={IqIconMobile} alt="IqIconMobile" /> */}
                  </div>
                  <h2>MarkitIQ</h2>
                  <p>
                    Blockchain enabled marketing intelligence and data analytics
                    which can be used to compute various systems for software,
                    databases, etc.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.subGrid}>
                <div className={styles.subGridItems}>
                  <div className={styles.cardImage}>
                    <img src={MarkitCard} alt="MarkitCard" />
                    <img src={MarkitCardMobile} alt="MarkitCardMobile" />
                  </div>
                </div>
                <div className={styles.subGridItems}>
                  <div className={styles.chainIcon}>
                    <img src={CardIcon} alt="CardIcon" />
                    {/* <img src={CardIconMobile} alt="CardIconMobile" /> */}
                  </div>
                  <h2>MarkitCard</h2>
                  <p>
                    A unique transactional card that is compatible with any form
                    of fiat or crypto (white label) while mining directly into
                    the blockchain
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
