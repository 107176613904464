import React, { useEffect, useState } from "react";
import useStickyHeader from "../../hook/useStickyHeader";
import ScrollbarHeader from "../Layout/scrollbarHeader";
import WhiteHeader from "../Layout/WhiteHeader";
import Loader from "../loader/index";
import AuthenticationLifecycle from "./authenticationLifecycle";
import AutomateBusiness from "./automateBusiness";
import DigitalWallets from "./digitalWallets";
import MarketingAnalytics from "./marketingAnalytics";
import PhysicalTethering from "./physicalTethering";
import styles from "./verification.module.scss";
export default function Verification() {
  const [loading, setLoading] = useState(true);
  const { scrollRef, isSticky } = useStickyHeader();
  useEffect(() => {
    if (loading) {
      let body = document.getElementById("body");
      body.classList.add("bodyHidden");
    }
    setTimeout(() => {
      setLoading(false);
      let body = document.getElementById("body");
      body.classList.remove("bodyHidden");
    }, 2000);
  }, []);

  return (
    <div ref={scrollRef}>
      {loading && <Loader />}
      <>
        <div re={scrollRef}>
          <div className={styles.verificationBanner}>
            {isSticky ? (
              <>
                <ScrollbarHeader />
              </>
            ) : (
              <>
                <WhiteHeader />
              </>
            )}
            <div className={styles.verificationContentCenter}>
              <div className="container-md">
                <div className={styles.pageTitle}>
                  <h1>
                    <a>Automated</a>
                    <br />
                    Authentication
                  </h1>
                  <p>
                    Our automated authentication process uses high level
                    blockchain tech, transforming the way we do traditional
                    authentication.{" "}
                  </p>
                  <div className={styles.buttonCenterAlign}>
                    <div className="white-button">
                      <a href="/about#ready-to-get-started">
                        <button>Get Started</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <>
          <AuthenticationLifecycle />
        </>
        <>
          <PhysicalTethering />
        </>
        <>
          <MarketingAnalytics />
        </>
        <>
          <DigitalWallets />
        </>
        <>
          <AutomateBusiness />
        </>
      </>
    </div>
  );
}
