import React from "react";
import DetailsImage from "../../../assets/Images/details-image.png";
import KeppShadowJson from "../../animationJson/keep-animation-shadow.json";
import InstantTransactionsJson from "../../animationJson/Instant-transactions.json";
import CurrencyJson from "../../animationJson/currency-json.json";
import LottiePlayOnScreen from "../../animationJson/LottiePlayOnScreen";

import styles from "./paymentService.module.scss";

export default function PaymentService() {

  return (
    <div>
      <div className={styles.paymentServiceSectionAlignment}>
        <div className="container-md">
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <div className={styles.currencyImagesAlignment}>
                <LottiePlayOnScreen
                  animationData={CurrencyJson}
                  id="CurrencyJson"
                  animationSpeed={0.9}
                />
                <div className={styles.detailsImageAlignment}>
                  <img src={DetailsImage} alt="DetailsImage" />
                </div>
              </div>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.sameTextGroupStyle}>
                <h1>Send and receive payments in any currency</h1>
                <p>
                  Tethered exchange conversion means instant transaction of all
                  forms of currency, digital and fiat, regardless of merchant
                  acceptance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.keepSalesSection}>
        <div className="container-md">
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <div className={styles.sameTextGroupStyle}>
                <h1>Keep 100% of all your sales</h1>
                <p>
                  This technology allows sellers to keep 100% of their sales,
                  unlike the other platforms where they lose up to 60-80% on
                  reseller fees of 20-40% on retail seller fees.
                </p>
              </div>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.imageStyle}>
                <LottiePlayOnScreen animationData={KeppShadowJson} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.instantTranactions}>
        <div className="container-md">
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <div className={styles.imageStyle}>
                <LottiePlayOnScreen
                  animationData={InstantTransactionsJson}
                  id="InstantTransactionsJson"
                  animationSpeed={0.7}
                />
              </div>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.sameTextGroupStyle}>
                <h1>Instant Transactions</h1>
                <p>
                  A patented, internal marketplace blockchain attaches true
                  value as an IP or escrow ledger that is directly backed by
                  commodities, creating a lossless way to purchase.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
