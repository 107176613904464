import React, { useEffect, useState } from "react";
import styles from "./blockchainMarketplace.module.scss";
import WhiteHeader from "../Layout/WhiteHeader/index";
import ProcessType from "./processType";
import FirstOfKit from "./firstOfKit";
import ExploreTheBenefits from "./exploreTheBenefits";
import FutureOfShopping from "./futureOfShopping";
import Loader from "../loader/index";
import useStickyHeader from "../../hook/useStickyHeader";
import ScrollbarHeader from "../Layout/scrollbarHeader";

export default function BlockchainMarketplace() {
  const [loading, setLoading] = useState(true);
  const { scrollRef, isSticky } = useStickyHeader();

  useEffect(() => {
    if (loading) {
      let body = document.getElementById("body");
      body.classList.add("bodyHidden");
    }
    setTimeout(() => {
      setLoading(false);
      let body = document.getElementById("body");
      body.classList.remove("bodyHidden");
    }, 2000);
  }, []);
  return (
    <div ref={scrollRef}>
      {loading && <Loader />}
      <>
        {isSticky ? (
          <>
            <div className={styles.BlockchainMarketplaceHeader}>
              <ScrollbarHeader />
            </div>
            <div id="123456" className={styles.blockchainMarketplaceBanner}>
              <div className={styles.blockchainMarketplaceContent}>
                <div className="container-md">
                  <h1>
                    <a>Blockchain</a> Marketplace
                  </h1>
                  <p>
                    With SaaS and blockchain digital platforms that propagate
                    ledgers across a real world fintech ecosystem of
                    applications, MyMarkit is able to provide first-of-its- kind
                    solutions for everyday access to blockchain utility for the
                    first time.
                  </p>
                  <div className="blue-button">
                    <a href="/about#ready-to-get-started">
                      <button>Get Started</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>{" "}
          </>
        ) : (
          <div className={styles.blockchainMarketplaceBanner}>
            <div className={styles.BlockchainMarketplaceHeader}>
              <WhiteHeader />
            </div>
            <div className={styles.blockchainMarketplaceContent}>
              <div className="container-md">
                <h1>
                  <a>Blockchain</a> Marketplace
                </h1>
                <p>
                  With SaaS and blockchain digital platforms that propagate
                  ledgers across a real world fintech ecosystem of applications,
                  MyMarkit is able to provide first-of-its- kind solutions for
                  everyday access to blockchain utility for the first time.
                </p>
                <div className="blue-button">
                    <a href="/about#ready-to-get-started">
                    <button>Get Started</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        <>
          <ProcessType />
        </>
        <>
          <FirstOfKit />
        </>
        <>
          <ExploreTheBenefits />
        </>
        <>
          <FutureOfShopping />
        </>
      </>
    </div>
  );
}
