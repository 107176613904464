import "./styles/mixins/global.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from "./components/home/index";
import About from "./components/About/index";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./styles/mixins/global.scss";
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer/index";
import BlackHeader from "./components/Layout/BlackHeader/index";
import UniversalBlockchain from "./components/UniversalBlockchain/index";
import { useEffect, useState } from "react";
import Verification from "./components/verification";
import WhiteLabel from "./components/whiteLabel";
import UniversalCurrency from "./components/universalCurrency/index";
import BlockchainMarketplace from "./components/blockchainMarketplace";
import MarketingAnalytics from "./components/marketingAnalytics";
import PaymentModal from "./components/modal/paymentModal";
import UniversalPaymentProcessor from './components/UniversalPaymentProcessor'
import UniversalWallet from './components/UniversalWallet'
import FourOFour from "./components/404";

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

const DefaultChildLayout = ({ children }) => (
  <>
    <Header />
    {children}
    <Footer />
  </>
);

const DefaultLayout = ({ children }) => (
  <>
    <BlackHeader />
    {children}
    <Footer />
  </>
);
const DefaultHeaderLayout = ({ children }) => (
  <>
    {children}
    <Footer />
  </>
);

function App() {
  const [, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      <BrowserRouter>
        <Switch>
          <RouteWrapper
            exact={true}
            path="/"
            component={Home}
            layout={DefaultChildLayout}
          />
          <RouteWrapper
            exact={true}
            path="/about"
            component={About}
            layout={DefaultLayout}
          />
          <RouteWrapper
            exact={true}
            path="/universal-blockchain"
            component={UniversalBlockchain}
            layout={DefaultHeaderLayout}
          />
          <RouteWrapper
            exact={true}
            path="/authentication"
            component={Verification}
            layout={DefaultHeaderLayout}
          />
          <RouteWrapper
            exact={true}
            path="/white-label"
            component={WhiteLabel}
            layout={DefaultHeaderLayout}
          />
          <RouteWrapper
            exact={true}
            path="/universal-currency"
            component={UniversalCurrency}
            layout={DefaultHeaderLayout}
          />
          <RouteWrapper
            exact={true}
            path="/blockchain-marketplace"
            component={BlockchainMarketplace}
            layout={DefaultHeaderLayout}
          />
          <RouteWrapper
            exact={true}
            path="/marketing-analytics"
            component={MarketingAnalytics}
            layout={DefaultHeaderLayout}
          />
          <RouteWrapper
            exact={true}
            path="/payment-modal"
            component={PaymentModal}
            layout={DefaultLayout}
          />
          <RouteWrapper
            path="/universal-payment-processor"
            component={UniversalPaymentProcessor}
            layout={DefaultHeaderLayout}
          />
          <RouteWrapper
            path="/universal-wallet"
            component={UniversalWallet}
            layout={DefaultHeaderLayout}
          />
          <RouteWrapper
            path="*"
            component={FourOFour}
            layout={DefaultHeaderLayout}
          />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
