import React from "react";
import Lottie from 'lottie-react'
import loadingAnimation from '../animationJson/loading_animation.json'
import "./loader.scss";

export default function Loader() {
  return (
    <div className="loader-alignment">
      <Lottie style={{width: 150}} animationData={loadingAnimation} />
    </div>
  );
}
