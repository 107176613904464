import React from "react";
import styles from "./ProcessTypeDetails.module.scss";
import UtilizeJson from "../../animationJson/Utilize-json.json";
import MineJson from "../../animationJson/Mine-json.json";
import LottiePlayOnScreen from "../../animationJson/LottiePlayOnScreen";
import PaymentInstantly from "../../PaymentInstantly"
import {ShowOnScreen} from "../../animationJson/LottiePlayOnScreen"

export default function ProcessTypeDetails() {
  return (
    <div>
      <div className={styles.processTypeDetailsSection}>
        <div className="container-md">
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <ShowOnScreen showThreshold={1}>
                <PaymentInstantly />
              </ShowOnScreen>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.typeDetailsText}>
                <h1>
                  <span>Process any type of payment instantly</span>
                </h1>
                <p>
                  All forms of digital and fiat currencies can instantly
                  transact for both purchases and sales, without gas fees.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.utilizeGrid}>
            <div className={styles.utilizeGridItems}>
              <div className={styles.typeDetailsText}>
                <h1>
                  <span>Utilize the world's first universal wallet</span>
                </h1>
                <p>
                  With a blockchain mining system backed to commodities from
                  direct transactions, MyMarkit is the only blockchain-enabled
                  marketplace in one of the largest payment platforms worldwide.
                </p>
              </div>
            </div>
            <div className={styles.utilizeGridItems}>
              <LottiePlayOnScreen
                id="UtilizeJson"
                animationSpeed={0.5}
                animationData={UtilizeJson}
              />
            </div>
          </div>
          <div className={styles.mindLedgersGrid}>
            <div className={styles.mindLedgersGridItems}>
              <LottiePlayOnScreen 
                id="MineJson" 
                animationSpeed={0.5}
                animationData={MineJson}
              />
            </div>
            <div className={styles.mindLedgersGridItems}>
              <div className={styles.typeDetailsText}>
                <h1>
                  <span>Mine ledgers directly from live transactions</span>
                </h1>
                <p>
                  A patented, internal marketplace blockchain attaches true
                  value as an IP or escrow ledger that is directly backed by
                  commodities, creating a lossless way to purchase.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
