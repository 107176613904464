import React from "react";
import styles from "./PowerOfBlockchain.module.scss";
export default function PowerOfBlockchain() {
  return (
    <div>
      <div className={styles.powerOfBlockchainBannner}>
        <div className="container-md">
          <h1>
            Get the power of <a>blockchain</a> under your brand name
          </h1>
          <div className={styles.buttonCenteralign}>
            <div className="white-button">
              <a href="/about#ready-to-get-started">
                <button>Get Started</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
