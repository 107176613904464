import React, {useRef, useEffect, useState} from 'react'
import Lottie from 'lottie-react'

import { useOnScreen } from '../../../hook/useOnScreen'

const LottiePlayOnScreen = ({
    animationData,
    animationSpeed=1.0,
    loop=false,
    id,
    showThreshold = 0.45,
    ...rest
}) => {
    const animationWrapperRef = useRef(null)
    const animationRef= useRef(null)
    const animationShouldPlay = useOnScreen(animationWrapperRef ,showThreshold)

    useEffect(() => {
        if (animationShouldPlay) {
            if (animationRef?.current) {
                animationRef.current.setSpeed(animationSpeed)
                animationRef.current.play()
            }
        }
    }, [animationShouldPlay, animationRef])
    return (
        <div ref={animationWrapperRef}>
            <Lottie
                id={id}
                ref={animationRef}
                autoplay={false}
                loop={loop}
                animationData={animationData}
                {...rest}
            />
        </div>
    )
}

export default LottiePlayOnScreen


export const ShowOnScreen = ({
    children,
    showThreshold=0.45,
    delay = 0,
    ...rest
}) => {
    const ref = useRef(null)
    const elementShouldShow = useOnScreen(ref ,showThreshold)
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        if (elementShouldShow && !visible) {
           if (delay) {
            setTimeout(() => setVisible(true), delay * 1000)
           } else {
            setVisible(true)
           }
        }
    }, [elementShouldShow])
    return (
        <div ref={ref} {...rest}>
            {visible && children}
        </div>
    )
}