import React from 'react'
import ComingSoonPage, {TextHightlight} from '../ComingSoon'

const UniversalPaymentProcessor = () => {
  return (
    <ComingSoonPage>
      <h1>COMING SOON</h1>
      <h3>
        <TextHightlight>
          Universal&nbsp;
        </TextHightlight>
          Payment Processor
      </h3>
      <p>
        MyMarkit is the only payment processor/wallet that bridges the gap between
        digital checkout and fiat payment schemes. With our blockchain mining system, we are able to process funds in any form of currency and remain within compliance/regulations for institutional banks and global payment methods.
      </p>
      <a href="/about#ready-to-get-started">
        <button>Learn More</button>
      </a>
    </ComingSoonPage>
  )
}

export default UniversalPaymentProcessor