import React from "react";
import styles from "./InformedDecisions.module.scss";
export default function InformedDecisions() {
  return (
    <div>
      <div className={styles.informedDecisionsBanner}>
        <div className="container">
          <div className={styles.pageTitle}>
            <h1>
              <span>
                Make informed decisions and see <a>real results</a>
              </span>
            </h1>
          </div>
          <div className={styles.cardAlignment}>
            <div className={styles.card}>
              <div className={styles.cardItems}>
                <h1>70%</h1>
                <p>Reduction in gas fees so you keep 100%</p>
              </div>
              <div className={styles.cardItems}>
                <h1>50+</h1>
                <p>Validated blockchain partner solutions</p>
              </div>
              <div className={styles.cardItems}>
                <h1>3x</h1>
                <p>Better performance than other solutions</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
