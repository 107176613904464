import React from "react";
import styles from "./NotableData.module.scss";
export default function NotableData() {
  return (
    <div data-scroll-section>
      <div className={styles.notableDataSection}>
        <div className="container-md">
          <h1>
            <span>MyMarkit Notable Data</span>
          </h1>
          <div className={styles.counterCenterAlignment}>
            <div>
              <h2>19</h2>
              <span>Employees</span>
            </div>
            <div>
              <h2>12</h2>
              <span>Partners</span>
            </div>
            <div>
              <h2>5K+</h2>
              <span>Customers</span>
            </div>
            <div>
              <h2>100%</h2>
              <span>Proven Technology</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
