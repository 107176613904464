import React, { useState } from "react";
import styles from "./askedQuestions.module.scss";
import classNames from "classnames";
export default function AskedQuestions() {
  const [textShow, setTextShow] = useState(false);
  const [textShow1, setTextShow1] = useState(false);
  const [textShow2, setTextShow2] = useState(false);
  const [textShow3, setTextShow3] = useState(false);

  return (
    <div>
      <div className={styles.askedQuestionsSection}>
        <div className="container-md">
          <div className={styles.pageTitle}>
            <h1>Frequently Asked Questions</h1>
          </div>
          <div className={styles.mainQuestion}>
            <div
              className={styles.questionAlignment}
              onClick={() => setTextShow(!textShow)}
            >
              <div>
                <h2>How does universal currency work?</h2>
              </div>
              <div>
                <i
                  className={
                    textShow ? "fa-solid fa-angle-up" : "fa-solid fa-angle-down"
                  }
                ></i>
              </div>
            </div>
            <div
              className={
                textShow
                  ? classNames(styles.childTextAlignment, styles.textShow)
                  : classNames(styles.childTextAlignment, styles.textHidden)
              }
            >
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Perspiciatis rerum dolor aliquid laborum atque obcaecati
                corporis, eligendi blanditiis magnam quo nostrum sequi sint rem
                a dolores voluptates quaerat ratione voluptate?
              </p>
            </div>
          </div>
          <div className={styles.mainQuestion}>
            <div
              className={styles.questionAlignment}
              onClick={() => setTextShow1(!textShow1)}
            >
              <div>
                <h2>How long will it take to receive my payment?</h2>
              </div>
              <div>
                <i
                  className={
                    textShow1
                      ? "fa-solid fa-angle-up"
                      : "fa-solid fa-angle-down"
                  }
                ></i>
              </div>
            </div>
            <div
              className={
                textShow1
                  ? classNames(styles.childTextAlignment, styles.textShow)
                  : classNames(styles.childTextAlignment, styles.textHidden)
              }
            >
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Perspiciatis rerum dolor aliquid laborum atque obcaecati
                corporis, eligendi blanditiis magnam quo nostrum sequi sint rem
                a dolores voluptates quaerat ratione voluptate?
              </p>
            </div>
          </div>
          <div className={styles.mainQuestion}>
            <div
              className={styles.questionAlignment}
              onClick={() => setTextShow2(!textShow2)}
            >
              <div>
                <h2>
                  Can I pay with Bitcoin if the merchant only accepts USD?{" "}
                </h2>
              </div>
              <div>
                <i
                  className={
                    textShow2
                      ? "fa-solid fa-angle-up"
                      : "fa-solid fa-angle-down"
                  }
                ></i>
              </div>
            </div>
            <div
              className={
                textShow2
                  ? classNames(styles.childTextAlignment, styles.textShow)
                  : classNames(styles.childTextAlignment, styles.textHidden)
              }
            >
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Perspiciatis rerum dolor aliquid laborum atque obcaecati
                corporis, eligendi blanditiis magnam quo nostrum sequi sint rem
                a dolores voluptates quaerat ratione voluptate?
              </p>
            </div>
          </div>
          <div className={styles.mainQuestion}>
            <div
              className={styles.questionAlignment}
              onClick={() => setTextShow3(!textShow3)}
            >
              <div>
                <h2>Do I need to download an app? </h2>
              </div>
              <div>
                <i
                  className={
                    textShow3
                      ? "fa-solid fa-angle-up"
                      : "fa-solid fa-angle-down"
                  }
                ></i>
              </div>
            </div>
            <div
              className={
                textShow3
                  ? classNames(styles.childTextAlignment, styles.textShow)
                  : classNames(styles.childTextAlignment, styles.textHidden)
              }
            >
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Perspiciatis rerum dolor aliquid laborum atque obcaecati
                corporis, eligendi blanditiis magnam quo nostrum sequi sint rem
                a dolores voluptates quaerat ratione voluptate?
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
