import React, { useEffect, useState } from "react";
import GetStarted from "./GetStarted";
import News from "./News";
import NotableData from "./NotableData";
import NotableMilestones from "./NotableMilestones";
import PlatformsMymarkit from "./PlatformsMymarkit";
import UniversalBlockchain from "./UniversalBlockchain";
import AboutImage from "../../assets/Images/about.png";
import Loader from "../loader/index";
import AdienceReached from "../animationJson/AdienceReached.json";
import LottiePlayOnScreen, { ShowOnScreen } from "../animationJson/LottiePlayOnScreen";

import styles from "./about.module.scss";

export default function About() {
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    if (loading) {
      let body = document.getElementById("body");
      body.classList.add("bodyHidden");
    }
    setTimeout(() => {
      setLoading(false);
      let body = document.getElementById("body");
      body.classList.remove("bodyHidden");
    }, 2000);
  }, []);

  return (
    <div>
      {loading && <Loader />}
      <div className={styles.aboutHeroSection}>
        <div className="container-md">
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <h1>
                <span>
                  Why <br />
                  MyMarkit?
                </span>
              </h1>
              <p>
                MyMarkit provides first-of-its-kind solutions for everyday
                access to blockchain utility.
              </p>
              <div className={styles.mobileViewButtonCenter}>
                <a href="/universal-blockchain">
                  <button>Learn More</button>
                </a>
              </div>
            </div>
            <div className={styles.gridItems}>
              <ShowOnScreen delay={0.55}>
              <div className={styles.aboutMainImage}>
                <img src={AboutImage} alt="AboutImage" />
              </div>
                <LottiePlayOnScreen
                    className={styles.audienceReachedJson}
                    animationData={AdienceReached}
                    id="AdienceReached"
                    animationSpeed={0.5}
                  />
              </ShowOnScreen>
            </div>
          </div>
        </div>
      </div>
      <>
        <UniversalBlockchain />
      </>
      <>
        <NotableData />
      </>
      <>
        <NotableMilestones />
      </>
      <>
        <PlatformsMymarkit />
      </>
      <>
        <News />
      </>
      <>
        <GetStarted />
      </>
    </div>
  );
}
