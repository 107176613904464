import React from "react";
import styles from "./PlatformsMymarkit.module.scss";
export default function PlatformsMymarkit() {
  return (
    <div>
      <div className={styles.platformsMymarkit}>
        <div className="container-md">
          <div className={styles.plaeformAlignment}>
            <div className={styles.textGrid}>
              <div className={styles.textGridItems}>
                <h1>
                  <span>
                    The world’s best ecommerce platforms trust MyMarkit
                  </span>
                </h1>
              </div>
              <div className={styles.textGridItems}>
                <p>
                  By partnering with one of the largest payment platforms,
                  MyMarkit is 1 of 5000 enterprise companies and the only
                  blockchain-enabled marketplace in Adyen's portfolio worldwide.
                </p>
              </div>
            </div>
            <div className={styles.platformsImageAlignment}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
