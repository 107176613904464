import React from "react";
import styles from "./JoinCommunity.module.scss";
export default function JoinCommunity() {
  return (
    <div>
      <div className={styles.joinCommunityBanner}>
        <div className="container">
          <div className={styles.joinCommunityBoxCenter}>
            <div className={styles.joinCommunityBox}>
              <h1>Join Our Community</h1>
              <p>Sign up for news, emails, and the latest from MyMarkit</p>
              <div className={styles.input}>
                <input type="text" placeholder="Enter your email" />
                <div className={styles.signupButton}>
                  <button>SIGN UP</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
