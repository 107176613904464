import React from "react";

import LottiePlayOnScreen from "../../animationJson/LottiePlayOnScreen";
import UserJson from "../../animationJson/user.json";
import GraphsJson from "../../animationJson/graphs.json";
import ChatJson from "../../animationJson/chat.json";

import styles from "./marketingAnalyticsStep.module.scss";

export default function MarketingAnalyticsStep() {

  return (
    <div>
      <div className={styles.marketingAnalyticsStep}>
        <div className="container-md">
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <div className={styles.iconCenterAlignment}>
                <LottiePlayOnScreen
                  id="UserJson"
                  animationData={UserJson}
                  animationSpeed={0.5}
                />
              </div>
              <h2>Engage your audience</h2>
              <p>
                MyMarkit’s novel blockchain technology will create a new class
                of physical tokens in the cryptocurrency world for the first
                time ever.
              </p>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.iconCenterAlignment}>
                <LottiePlayOnScreen
                  id="GraphsJson"
                  animationData={GraphsJson}
                  animationSpeed={0.5}
                />
              </div>
              <h2>Receive advanced analytics</h2>
              <p>
                MyMarkit’s novel blockchain technology will create a new class
                of physical tokens in the cryptocurrency world for the first
                time ever.
              </p>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.iconCenterAlignment}>
                <LottiePlayOnScreen
                  id="ChatJson"
                  animationData={ChatJson}
                  animationSpeed={0.5}
                />
              </div>
              <h2>Real-time feedback</h2>
              <p>
                MyMarkit’s novel blockchain technology will create a new class
                of physical tokens in the cryptocurrency world for the first
                time ever.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
