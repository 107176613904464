import React from "react";
import styles from "./Footer.module.scss";
import FacebookIcon from "../../../assets/icons/facebook.svg";
import TwitterIcon from "../../../assets/icons/twitter.svg";
import MyMarkitInc from "../../../assets/logo/my-markit-inc.svg";
import InstagramIcon from "../../../assets/icons/instagram.svg";

export default function Footer() {
  return (
    <div>
      <div className={styles.footer}>
        <div className="container">
          <div className={styles.footerGrid}>
            <div className={styles.footerGridItems}>
              <div className={styles.footerLogo}>
                <img src={MyMarkitInc} alt="MyMarkitInc" />
                <div className={styles.getInTouch}>
                  <label>Get in Touch</label>
                  <div className={styles.relativeDiv}>
                    <input type="text" placeholder="Enter your email" />
                    <div className={styles.signUpButton}>
                      <button>Sign Up</button>
                    </div>
                  </div>
                </div>
                <div className={styles.socialMedia}>
                  <div>
                    <img src={FacebookIcon} alt="FacebookIcon" />
                  </div>
                  <div>
                    <img src={TwitterIcon} alt="TwitterIcon" />
                  </div>
                  <div>
                    <img src={InstagramIcon} alt="InstagramIcon" />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.footerGridItems}>
              <div className={styles.footerSubMenuAlign}>
                <div>
                  <h2>Solutions</h2>
                  <ul>
                    <a href="/universal-blockchain">
                      <li>Universal Blockchain</li>
                    </a>
                    <a href="/universal-currency">
                      <li>Universal Currency</li>
                    </a>
                    <a href="/universal-payment-processor">
                      <li>Universal Payment Processor (coming soon)</li>
                    </a>
                    <a href="/universal-wallet">
                      <li>Universal Wallet (coming soon)</li>
                    </a>
                    <a href="/blockchain-marketplace">
                      <li>Blockchain Marketplace</li>
                    </a>
                    <a href="/marketing-analytics">
                      <li>Marketing Analytics</li>
                    </a>
                  </ul> 
                </div>
                <div>
                  <h2>About</h2>
                  <ul>
                    <a href="/white-label">
                      <li>White Label</li>
                    </a>
                    <a href="/authentication">
                      <li>Authentication</li>
                    </a>
                    <a href="/about">
                      <li>About MyMarkit Inc</li>
                    </a>
                  </ul>
                </div>

                <div>
                  <h2>Documentation</h2>
                  <ul>
                    {/* <a>
                      <li>Plug-in Info</li>
                    </a> */}
                    <a href="https://mymarkit.redoc.ly/" target="_blank" rel="noreferrer">
                      <li>API Info</li>
                    </a>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.copyrightSection}>
            <p>Questions? info@mymarkit.com</p>
            <p>Copyright © 2022 MyMarkit Inc</p>
          </div>
        </div>
      </div>
    </div>
  );
}
