import React from 'react'
import WhiteHeader from '../Layout/WhiteHeader'
import styles from './ComingSoon.module.scss'

const ComingSoonPage = ({children}) => {
  return (
    <div className={styles.ComingSoon}>
      <WhiteHeader />
      <div className={styles.Header}>
        {children}
      </div>
    </div>
  )
}

export const TextHightlight = ({ children }) => {
  return (
    <span className={styles.Highlight}>{children}</span>
  )
}

export default ComingSoonPage