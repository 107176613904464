import React, { useEffect, useState } from "react";
import BringSolutions from "./bringSolutions";
import EngageYourAudience from "./engageYourAudience";
import MarketingAnalyticsBanner from "./marketingAnalyticsBanner";
import MarketingAnalyticsStep from "./marketingAnalyticsStep";
import ReachYourGoals from "./reachYourGoals";
import Loader from "../loader/index";

export default function MarketingAnalytics() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      let body = document.getElementById("body");
      body.classList.add("bodyHidden");
    }
    setTimeout(() => {
      setLoading(false);
      let body = document.getElementById("body");
      body.classList.remove("bodyHidden");
    }, 2000);
  }, []);

  return (
    <div>
      {loading && <Loader />}
      <>
        <>
          <MarketingAnalyticsBanner />
        </>
        <>
          <MarketingAnalyticsStep />
        </>
        <>
          <ReachYourGoals />
        </>
        <>
          <BringSolutions />
        </>
        <>
          <EngageYourAudience />
        </>
      </>
    </div>
  );
}
