import React from "react";
import styles from "./marketingAnalytics.module.scss";
import MarketingAnalyticsJson from "../../animationJson/marketing-analytics.json";
import LottiePlayOnScreen from "../../animationJson/LottiePlayOnScreen";

export default function MarketingAnalytics() {
  return (
    <div>
      <div className={styles.marketingAnalyticsBanner}>
        <div className="container-lg">
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <h1>Marketing analytics</h1>
              <p>
                Every transaction ledger encapsulates data packets of actual
                user purchasing and selling information. This is not like a
                marketing ticker and is tied to actual monetary amounts,
                accruing valuable insights and behavior for user shopping over
                time.
              </p>
            </div>
            <div className={styles.gridItems}>
              <LottiePlayOnScreen
                animationSpeed={0.5}
                animationData={MarketingAnalyticsJson}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
