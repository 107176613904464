import React from 'react'
import universalImageLeft from '../../assets/Images/universal_payment_left.png'
import universalImageRight from '../../assets/Images/universal_payment_right.png'
import './styles.scss'

const PaymentInstantly = () => {
return (
<div className="PaymentInstantly">
    <img src={universalImageLeft} />
    <img src={universalImageRight} />
</div>
)
}

export default PaymentInstantly