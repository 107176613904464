import React from "react";
import styles from "./blockchainEcommerce.module.scss";
import DummyImage from "../../../assets/Images/dummy-image.webp";
import MintingImage from "../../../assets/Images/minting.webp";
import IssuanceImage from "../../../assets/Images/issuance-image.webp";
export default function BlockchainEcommerce() {
  return (
    <div>
      <div className={styles.blockchainEcommerceAlign}>
        <div className="container-md">
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <img src={DummyImage} alt="DummyImage" />
            </div>
            <div className={styles.gridItems}>
              <h1>White label blockchain ecommerce</h1>
              <p>
                All forms of digital and fiat currencies can instantly transact
                for both purchases and sales, without gas fees.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.anewMintingProcess}>
        <div className="container-md">
          <div className={styles.subGrid}>
            <div className={styles.subGridItems}>
              <h1>A new minting process</h1>
              <p>
                With a blockchain mining system backed to commodities from
                direct transactions, MyMarkit is the only blockchain-enabled
                marketplace in one of the largest payment platforms worldwide.
              </p>
            </div>
            <div className={styles.subGridItems}>
              <img src={MintingImage} alt="MintingImage" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.digitalWalletsSectionAlignent}>
        <div className="container-md">
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <img src={IssuanceImage} alt="IssuanceImage" />
            </div>
            <div className={styles.gridItems}>
              <h1>Digital wallets & card issuance</h1>
              <p>
                All forms of digital and fiat currencies can instantly transact
                for both purchases and sales, without gas fees.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
