import React from "react";
import DigitalIdentifiersJson from "../../animationJson/digital-Identifiers.json";
import LicencesJson from "../../animationJson/licences.json";
import TokenJson from "../../animationJson/token.json";
import LottiePlayOnScreen from "../../animationJson/LottiePlayOnScreen";

import styles from "./whiteLabelService.module.scss";

export default function WhiteLabelService() {

  return (
    <div>
      <div className={styles.whiteLabelServiceAlign}>
        <div className="container-md">
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <div className={styles.iconCenterAlignment}>
                <LottiePlayOnScreen
                  id="DigitalIdentifiersJson"
                  animationSpeed={0.5}
                  animationData={DigitalIdentifiersJson}
                />
              </div>
              <h2>Digital identifiers</h2>
              <p>
                Non-fungible digital identifier certifies authenticity of every
                product through a proprietary, first-of-its-kind ledger system.
              </p>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.iconCenterAlignment}>
                <LottiePlayOnScreen
                  id="LicencesJson"
                  animationSpeed={0.5}
                  animationData={LicencesJson}
                />
              </div>
              <h2>White label licenses</h2>
              <p>
                White label licenses and store channel integrations allow brands
                to integrate with real-world commerce easily.
              </p>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.iconCenterAlignment}>
                <LottiePlayOnScreen
                  id="TokenJson"
                  animationSpeed={0.5}
                  animationData={TokenJson}
                />
              </div>
              <h2>White label token</h2>
              <p>
                A white label universal token is formed, which can be used for
                any number of use cases (stablecoin or tokens).
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
