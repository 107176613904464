/* eslint-disable no-tabs */
import {useState, useEffect } from 'react'

export function useOnScreen(ref, showThreshold=0) {
	const [isIntersecting, setIntersecting] = useState(false)

	const observer = new IntersectionObserver(
		([entry]) => {
			setIntersecting(entry.isIntersecting)
		},
		{
			threshold: showThreshold
		}
	)

	useEffect(() => {
	if (isIntersecting) {
		observer.disconnect()
	} else {
		observer.observe(ref.current)
	}
	// Remove the observer as soon as the component is unmounted
	return () => {
	observer.disconnect() 
	}
	}, [isIntersecting])

	return isIntersecting
}