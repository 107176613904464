import React from "react";
import styles from "./GetStarted.module.scss";
export default function GetStarted() {
  return (
    <div id="ready-to-get-started">
      <div className={styles.getStartedSection}>
        <div className="container">
          <div className={styles.pageTitle}>
            <h1>Ready to get started?</h1>
          </div>
          <div className={styles.boxCenterAlignment}>
            <div className={styles.getStartBox}>
              <div className={styles.firstInputGrid}>
                <div className={styles.firstInputGridItems}>
                  <div className={styles.input}>
                    <input type="text" placeholder="First Name" />
                  </div>
                </div>
                <div className={styles.firstInputGridItems}>
                  <div className={styles.input}>
                    <input type="text" placeholder="Last Name" />
                  </div>
                </div>
              </div>
              <div className={styles.emailInput}>
                <div className={styles.input}>
                  <input type="text" placeholder="Email" />
                </div>
              </div>
              <div className={styles.firstInputGrid}>
                <div className={styles.firstInputGridItems}>
                  <div className={styles.input}>
                    <input type="text" placeholder="Phone Number" />
                  </div>
                </div>
                <div className={styles.firstInputGridItems}>
                  <div className={styles.input}>
                    <input type="text" placeholder="Company" />
                  </div>
                </div>
              </div>
              <div className={styles.emailInput}>
                <div className={styles.input}>
                  <textarea placeholder="Tell us what we can help you with"></textarea>
                </div>
              </div>
              <div className={styles.submitForm}>
                <button>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
