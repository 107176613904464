import React from "react";
import styles from "./marketingAnalyticsBanner.module.scss";
import WhiteHeader from "../../Layout/WhiteHeader/index";
import SalesOverviewJson from "../../animationJson/SalesOverview-json1.json";
import ScrollbarHeader from "../../Layout/scrollbarHeader";
import useStickyHeader from "../../../hook/useStickyHeader";
import LottiePlayOnScreen from "../../animationJson/LottiePlayOnScreen";
export default function MarketingAnalyticsBanner() {
  const { scrollRef, isSticky } = useStickyHeader();


  return (
    <div ref={scrollRef}>
      <div className={styles.marketingAnalyticsBanner}>
        {isSticky ? (
          <>
            <ScrollbarHeader />
          </>
        ) : (
          <>
            <WhiteHeader />
          </>
        )}
        <div className={styles.heroTextAlignment}>
          <div className={styles.heroText}>
            <h1>
              Get <a>traceability data</a> <br />
              on live transactions, right when they happen
            </h1>
            <p>
              With MyMarkit’s marketing analytics, every transaction ledger
              encapsulates data packets of actual user purchasing and seller
              information.
            </p>
            <div className={styles.buttonCenterAlignment}>
              <div className="white-button">
                <a href="/about#ready-to-get-started">
                  <button>Get Started</button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container-md">
          <div className={styles.salesOverviewAlignment}>
            <LottiePlayOnScreen
              showThreshold={0.3}
              animationSpeed={0.3}
              id="SalesOverviewJson"
              animationData={SalesOverviewJson}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
