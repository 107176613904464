import React from 'react'
import WhiteHeader from '../Layout/WhiteHeader'
import styles from './FourOFour.module.scss'

const FourOFour = () => {
  console.log(styles)
  return (
    <div
      className={styles.FourOFour}
    >
      <WhiteHeader />
      <div className={styles.Header}>
        <h1>
          404
        </h1>
        <p>
          Looks like the page you are <br />
          looking for does not exist.
        </p>
        <a href='/'>
          <button role='link'>
            Return home
          </button>
        </a>
      </div>
    </div>
  )
}

export default FourOFour