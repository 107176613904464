import React, { useEffect, useState } from "react";
import WhiteHeader from "../Layout/WhiteHeader";
import styles from "./whiteLabel.module.scss";
import WhiteLabelService from "./whiteLabelService";
import ScaleYourBusiness from "./scaleYourBusiness";
import BlockchainEcommerce from "./blockchainEcommerce";
import PowerOfBlockchain from "./PowerOfBlockchain";
import Loader from "../loader/index";
import useStickyHeader from "../../hook/useStickyHeader";
import ScrollbarHeader from "../Layout/scrollbarHeader";

export default function WhiteLabel() {
  const [loading, setLoading] = useState(true);
  const { scrollRef, isSticky } = useStickyHeader();

  useEffect(() => {
    if (loading) {
      let body = document.getElementById("body");
      body.classList.add("bodyHidden");
    }
    setTimeout(() => {
      setLoading(false);
      let body = document.getElementById("body");
      body.classList.remove("bodyHidden");
    }, 2000);
  }, []);

  return (
    <div ref={scrollRef}>
      {loading && <Loader />}
      <>
        <div className={styles.whiteLabelBanner}>
          {isSticky ? (
            <>
              <ScrollbarHeader />
            </>
          ) : (
            <>
              <WhiteHeader />
            </>
          )}
          <div className={styles.whitelabelConetentCenter}>
            <div className="container">
              <div className={styles.pageTitle}>
                <h1>
                  Run your business on our <a>blockchain</a>
                </h1>
                <p>
                  MyMarkit offers white label licenses and store channel
                  integrations. NFT and brands can easily integrate with
                  real-world commerce, tokenize with real transactions, receive
                  card issuance, and gain everyday user adoption.
                </p>
                <div className={styles.buttonCenterAlignment}>
                  <div className="white-button">
                    <a href="/about#ready-to-get-started">
                      <button>Get Started</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <>
          <WhiteLabelService />
        </>
        <>
          <ScaleYourBusiness />
        </>
        <>
          <BlockchainEcommerce />
        </>
        <>
          <PowerOfBlockchain />
        </>
      </>
    </div>
  );
}
