import React from "react";
import styles from "./digitalWallets.module.scss";
import AuthenticationImage from "../../../assets/Images/authentication-image.webp";
import InventoryProcessImage from "../../../assets/Images/inventory-process.webp";
import ComplianceImage from "../../../assets/Images/compliance-image.webp";
import WalletsJson from "../../animationJson/wallets.json";
import LottiePlayOnScreen from "../../animationJson/LottiePlayOnScreen";
export default function DigitalWallets() {
  return (
    <div>
      <div className={styles.digitalWalletsSection}>
        <div className="container-lg">
          <div className={styles.grid}>
            <div className={styles.gridItems} style={{ height: '100%', width: '100%' }}>
              <LottiePlayOnScreen animationData={WalletsJson} />
            </div>
            <div className={styles.gridItems}>
              <h1>Digital Wallets & Card issuance</h1>
              <p>
                A patented, internal marketplace blockchain attaches true value
                as an IP or escrow ledger that is directly backed by
                commodities, creating a lossless way to purchase.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.moreThanDataAlignment}>
        <div className="container-lg">
          <div className={styles.pageTitle}>
            <h1>
              <span>More than</span> just data
            </h1>
          </div>
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <div className={styles.cardImage}>
                <img src={AuthenticationImage} alt="AuthenticationImage" />
              </div>
              <div className={styles.cardDetails}>
                <h3>Understand your customers on a whole new level</h3>
                <p>
                  Need text here. Automate your transactions with a private,
                  centralized blockchain system using first-of-its-kind ledger
                  technology for fulfillment, authentication, verification,
                  tracking.
                </p>
              </div>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.cardImage}>
                <img src={InventoryProcessImage} alt="InventoryProcessImage" />
              </div>
              <div className={styles.cardDetails}>
                <h3>
                  Streamline your <br />
                  inventory process
                </h3>
                <p>
                  Need text here. With MyMarkit’s tethered exchange conversion
                  technology, sellers keep 100% of their sales–unlike the 60-
                  80% reseller fees and 20-40% retail seller fees other
                  platforms require.
                </p>
              </div>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.cardImage}>
                <img src={ComplianceImage} alt="ComplianceImage" />
              </div>
              <div className={styles.cardDetails}>
                <h3>Ensure authentication compliance</h3>
                <p>
                  Need text here. Use the only payment processor that processes
                  every form of currency while remaining in compliance with
                  institutional banks and global payment methods.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
