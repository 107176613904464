import React from "react";
import styles from "./scaleYourBusiness.module.scss";
import FullLayerImage from "../../../assets/Images/full-layer-image.png";
import FullLayerMobileImage from "../../../assets/Images/full-layer-image-mobile.png";
export default function ScaleYourBusiness() {
  return (
    <div>
      <div className="container-md">
        <div className={styles.fullLayerImageAlignment}>
          <img src={FullLayerImage} alt="FullLayerImage" />
          <img src={FullLayerMobileImage} alt="FullLayerMobileImage" />
        </div>
      </div>
      <div className={styles.scaleSectionAlignment}>
        <div className="container-md">
          <div className={styles.textGrid}>
            <div className={styles.textGridItems}>
              <h1>Scale your business like never before</h1>
            </div>
            <div className={styles.textGridItems}>
              <p>
                MyMarkit’s partnerships with major exchanges, chains, tokens,
                wallets, and NFT companies allow scalability within the Web3
                ecosystem.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
