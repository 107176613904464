import React from "react";
import styles from "./engageYourAudience.module.scss";
export default function EngageYourAudience() {
  return (
    <div>
      <div className={styles.engageYourAudienceBanner}>
        <div className="container-md">
          <h1>
            Engage your audience and <br /> <a>get more data</a>
          </h1>
          <div className={styles.buttonCenterAlignment}>
            <div className="blue-button">
              <a href="/about#ready-to-get-started">
                <button>Get Started</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
