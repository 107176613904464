import React from "react";
import styles from "./growthAndSecurity.module.scss";
export default function GrowthAndSecurity() {
  return (
    <div>
      <div className={styles.growthAndSecurityBanner}>
        <div className="container-md">
          <h1>
            Balance <a>growth and security</a> for your business
          </h1>
          <div className="blue-button">
            <a href="/about#ready-to-get-started">
              <button>Get Started</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
